export default {
  "Вход в кабинет": "Kirish",
  departments: "Bo'limlar",
  ПИНФЛ: "JSHSHIR",
  all_departments: "Barcha Bo'limlar",
  "Категории тикетов": "Tiket toifalari",
  "Все категории тикетов": "Barcha tiket toifalari",
  "Добавить новую категорию тикета": "Yangi tiket toifasini qo'shish",
  "Наименование на узбекском": "Nomi o'zbek tilida",
  "Наименование на русском": "Nomi rus tilida",
  "Наименование на английском": "Nomi ingliz tilida",
  "Дата завершения тикета": "Tiket tugash sanasi",
  "Создать категорию": "Kategoriya yaratish",
  "Категории часто задаваемых вопросов": "FAQ toifalari",
  "Все категории часто задаваемых вопросов": "Barcha FAQ toifalari",
  "Добавить категорию часто задаваемых вопросов": "FAQ toifasini qo'shish",
  Статьи: "Maqolalar",
  Шаблоны: "Shablonlar",
  Содержание: "Kontent",
  "Все статьи": "Barcha maqolalar",
  "Добавить новую статью": "Yangi maqola qo'shish",
  Категория: "Toifa",
  "Создать статью": "Maqola yaratish",
  "Изменить категорию": "Toifani o'zgartirish",
  "Редактировать статью": "Maqolani tahrirlash",
  "Все шаблоны": "Barcha shablonlar",
  "Добавить новый шаблон": "Yangi shablon qo'shish",
  "Редактировать шаблон": "Shablonni tahrirlash",
  Тикеты: "Tiketlar",
  "Все тикеты": "Barcha tiketlar",
  "Добавить новый тикет": "Yangi tiket qo'shish",
  "Создание тикета": "Tiketni yaratish",
  Мужской: "Erkak",
  Женский: "Ayol",
  Вакант: "Bo'sh",
  Пол: "Jinsi",
  Возраст: "Yosh",
  лет: "yosh",
  "Дни рождения": "Tug'ilgan kunlar",
  "Дней осталось": "Nechi kun qoldi",
  Сегодня: "Bugun",
  Завтра: "Ertaga",
  "дней осталось": "kun qoldi",
  Руководитель: "Boshqaruv xodim",
  Специалист: "Mutaxassis",
  Помощник: "Xizmatchi",
  "Тип сотрудника": "Xodim tur",
  "Технический специалист": "Texnik xodim",
  "Нет данных": "Ma'lumot topilmadi",
  "Название отдела согласно штатному расписанию":
    "Shtat jadvaliga muvofiq bo'lim nomi",
  "Прикрепленные файлы": "Biriktirilgan fayllar",
  Заявитель: "Ariza beruvchi",
  Простой: "Simple",
  Отклоненный: "Rejected",
  Успешный: "Success",
  "Тип этапа": "Bosqich turi",
  "Все подразделения": "Barcha bo'linmalar",
  "Текущее подразделение": "Joriy bo'linma",
  "Скачать excel": "Excelni yuklab olish",
  Структура: "Struktura",
  "Загрузить лиды через Excel": "Yetakchilarni Excel orqali yuklang",
  Госнаграды: "Davlat mukofotlari",
  "У данного сотрудника пока отсутствуют госнаграды":
    "Ushbu xodimda hozircha davlat mukofotlari mavjud emas.",
  "У данного сотрудника пока отсутствуют сертификаты":
    "Ushbu xodimda hozircha davlat sertifikatlar mavjud emas.",
  "Дата перевода": "O'tkazish sanasi",
  employed: "ishga kabul kilindi",
  dismissed: "ishdan bushadi",
  changed: "boshka ishga utkazildi",
  "История передвижений не найдена": "Harakatlar tarixi topilmadi",
  "Информация не предоставлена": "Ma'lumot berilmagan",
  "Создать госнаграду": "Davlat mukofotini qo'shish",
  "Редактировать госнаграду": "Davlat mukofotini tahrirlash",
  "Удалить госнаграду": "Davlat mukofotini o'chirish",
  Год: "Yil",
  "Все госнаграды": "Barcha davlat mukofotlari",
  Комментарий: "Izoh",
  "Электронная почта": "Elektron pochta",
  Уведомления: "Bildirishnomalar",
  "Вам поступила новая задача в модуле TASKS":
    "Siz TASKS modulida yangi topshiriq oldingiz",
  "Создана карточка, которая по названию имеет совпадение с ранее созданными другими карточками.":
    "<b>{name}</b> kartasi yaratildi, u ilgari yaratilgan boshqa kartalar bilan bir xil.",
  "Карточка была переведена на этап Rejected":
    "<b>{company}</b> kartochkasi <b>{name}</b> tomonidan Rejected bosqichiga o‘tkazildi.",
  "Вы создаете карточку с компанией":
    "Siz nomi ilgari yaratilgan boshqa kartalarga mos keladigan kompaniya bilan karta yaratiyapsiz. Haqiqatan ham shu nomli karta yaratmoqchimisiz?",
  "Через 15 дней карточка переместится на этап Postponed":
    "15 kundan keyin karta Postponed bosqichga o'tadi",
  "Через 3 дня карточка будет скрыта с данного этапа":
    "3 kundan keyin karta ushbu bosqichdan yashiriladi",
  Понятно: "Tushunarli",
  "Данную карточку нельзя переместить на другие этапы, так как карточка уже добавлена в CRM систему.":
    "Ushbu kartani boshqa bosqichlarga o'tkazib bo'lmaydi, chunki karta CRM tizimiga qo'shilgan.",
  "Чтобы переместить карточку на этап Lead, нужно сначала его взять в работу.":
    "Kartani Lead bosqichga o'tkazish uchun avval uni ishga olishingiz kerak.",
  "Карточку с данного этапа можно перевести только на этап Contact.":
    "Ushbu bosqichdagi karta faqat Contact bosqichiga o'tkazilishi mumkin.",
  "Карточку с данного этапа можно перевести только на этапы Lead и Rejected.":
    "Ushbu bosqichdagi karta faqat Lead va Rejected bosqichlariga o'tkazilishi mumkin.",
  "Карточку с данного этапа нельзя перевести на этап Contact.":
    "Ushbu bosqichdagi kartani Contact bosqichiga o'tkazib bo'lmaydi.",
  Модуль: "Modul",
  "Название карточки": "Karta nomi",
  "Программа Local 2 Global": "Local 2 Global dasturi",
  "Название компании": "Tashkilot nomi",
  "Номер заявления": "Ariza raqami",
  "Все регионы": "Barcha viloyatlar",
  "Дата (от)": "Ushbu sanadan",
  "Дата (по)": "Ushbu sanagacha",
  "Статус заявления": "Ariza holati",
  Очистить: "Tozalash",
  Поиск: "Qidiruv",
  "Количество заявок": "Arizalar soni",
  Регион: "Region",
  "Общий рейтинг": "Overall rating",
  ИНН: "STIR",
  "Дата подачи": "Berilgan sana",
  Статус: "Status",
  Действия: "Harakat",
  Черновик: "Chernovik",
  "На расмотрении": "Ko'rib chiqilmoqda",
  "Отказано в рассмотрении": "Ko'rib chiqishdan rad etilgan",
  "Возвращено на доработку": "Qayta ko'rib chiqish uchun qaytarildi",
  Подтвержден: "Tasdiqlangan",
  "Нет записей": "Ma'lumotlar yo'q",
  Подтвердить: "Tasdiqlash",
  Отозвать: "Qaytarib olish",
  Скачать: "Yuklash",
  "Сведения о компании": "Tashkilot bo'yicha ma`lumot",
  "ИНН компании": "Tashkilot STIR",
  "Дата регистрации/Номер регистрации":
    "Ro‘yxatdan o‘tgan sana/Ro‘yxatdan o‘tgan raqami",
  "Юридический адрес": "Yuridik manzil",
  "ФИО директора": "Direktorning F.I.Sh.",
  Контакты: "Bog'lanish uchun ma'lumot",
  "Номер телефона директора": "Direktorning telefon raqami",
  "Номер телефона ответственного лица": "Mas'ul shaxsning telefon raqami",
  "Электронная почта для отправки уведомлений":
    "Xabar yuborish uchun elektron manzil",
  "Веб-сайт": "Veb-sayt",
  "Тип компании": "Korxona turi",
  Сервисная: "Xizmat ko‘rsatuvchi",
  "Виды разрабатываемого программного обеспечения":
    "Ishlab chiqilayotgan dasturiy ta`minot turlari",
  "Языки программирования": "Dasturlash tili",
  "Базы данных": "Ma`lumotlar bazasi",
  Фреймворки: "Freymvorklar",
  Продуктовая: "O‘z mahsulotlariga ega korxona",
  "Краткое описание продукта": "Mahsulot haqida qisqacha ma'lumot",
  "Предназначение продукта": "Mahsulotning ixtsoslanishi",
  "Образовательное учреждение": "Ta'lim muassasasi",
  "Перечень образовательных курсов": "O‘quv kurslar ro'yxati",
  "Наличие филиалов": "Filiallar mavjudmi?",
  Да: "Ha",
  Нет: "Yo'q",
  Есть: "Bor",
  Консалтинговая: "Konsalting xizmatlari",
  "Хостинг и регистратор доменов": "Xosting va domen registratorlari",
  Кибербезопасность: "Kiberxavfsizlik xizmatlari",
  "Деятельность венчурного финансирования и акселерации":
    "Venchur moliyalashtirish va akseleratsiya faoliyati",
  "Услуги киберспорта": "Kibersport xizmati",
  "Услуги в сфере мультимедиа и дизайна":
    "Multimedia va dizayn sohasida xizmat ko'rsatish",
  "Количество филиалов": "Filiallar soni",
  Специальность: "Mutaxassis",
  "Количество единиц": "Soni",
  Добавить: "Qo'shish",
  Далее: "Keyingi",
  "Сохранить как черновик": "Chernovik(Qoralama) sifatida saqlash",
  "Информация о сотрудниках": "Xodimlar bo'yicha ma`lumot",
  "Финансовая информация": "Moliyaviy ma`lumot",
  "Доход от экспортных операций в течение последнего года":
    "Eksport operatsiyalaridan xizmatlar sotishdan tushgan daromad oxirgi yil ichida",
  usd: "USD",
  "Список стран, куда был выполнен экспорт услуг/продуктов":
    "Xizmatlar/mahsulotlar eksport qilingan davlatlar roʻyxati",
  "Доход от оказанных услуг в течение последнего года":
    "Xizmatlarni sotishdan tushgan daromad oxirgi yil ichida",
  "тыс. сум": "ming so'm",
  "Краткое описание деятельности компании":
    "Kompaniya faoliyatining qisqacha tavsifi",
  "Описание продукта/сервиса": "Mahsulot/xizmat tavsifi",
  "Ключевые достижения компании": "Kompaniyaning asosiy yutuqlari",
  "Почему вы хотите принять участие в программе Local2Global?":
    "Nega siz Local2Global dasturida qatnashmoqchisiz?",
  "Загрузить портфолио (в формате pdf, ppt не более 15 МБ)":
    "Portfelni yuklash (15MB dan ko'p bo'lmagan pdf, ppt formatdagi fayl)",
  "Дополнительная информация": "Qo'shimcha ma'lumotlar",
  "История действий по заявлению": "Ariza bo'yicha harakatlar tarixi",
  Исполнитель: "Ijrochi",
  "Дата действия": "Amal qilish sanasi",
  "Статус действия": "Harakat holati",
  "на русском": "ruschada",
  "на узбекском": "o'zbekchada",
  "на английском": "inglizchada",
  Отправить: "Yuborish",
  Отменить: "Bekor qilish",
  Внимание: "Diqqat",
  "Заявки на участие": "Ishtirok etish uchun arizalar",
  "Заявки на покрытие расходов": "Xarajatlarni qoplash uchun arizalar",
  "Дата начала договора (от)": "Дата начала договора (от)",
  "Дата начала договора (до)": "Дата начала договора (до)",
  "Возраст (от)": "Yosh (dan)",
  "Возраст (до)": "Yosh (gacha)",
  "Гражданин Узбекистана": "O‘zbekiston fuqarosi",
  "Иностранный гражданин": "Chet el fuqarosi",
  "Выберите сотрудника": "Xodimni tanlang",
  "Передать в Экспертный совет": "Ekspert kengashiga taqdim etish",
  "Передано в Экспертный совет": "Ekspert kengashiga yuborildi",
  Отклонён: "Rad etildi",
  "Член Экспертного совета": "Ekspertlar kengashi aʼzosi",
  "Дата и время решения": "Qaror qabul qilingan sana va vaqt",
  "Результаты рассмотрения Экспертного совета":
    "Ekspert kengashi tomonidan ko'rib chiqish natijalari",
  "Экспертный совет рассмотрел": "Ekspertlar kengashi ko'rib chiqdi",

  Степень: "Darajasi",
  Бакалавр: "Bakalavr",
  Магистратура: "Magistratura",
  "Название учебного заведения": "Ta'lim muassasasining nomi",
  "Дата начала: месяц, год": "Boshlanish sanasi: oy, yil",
  "Дата начала": "Boshlanish sanasi",
  "Дата окончания: месяц, год": "Tugash sanasi: oy, yil",
  "Дата окончания": "Tugash sanasi",
  Специализация: "Mutaxassislik",
  Сохранить: "Saqlash",
  Таблица: "Table",
  "Внесенные данные": "Entered data",
  "Внесенные данные в Local team": "Local jamoadagi kiritilingan ma'lumotlar",
  "Внесенные данные в Talents": "Talents'dagi kiritilingan ma'lumotlar",
  "Подтвержденные данные в Talents": "Talents'dagi tasdiqlangan ma'lumotlar",
  "Внести данные": "Ma'lumotlarni kiriting",
  "Изменение внесения данных": "Ma'lumotlar kiritishni o'zgartirish",
  "Внесение данных": "Ma'lumotlarni kiritish",
  "Имеется ли сотрудник со знанием английского языка? (Да/Нет)": "Ingliz tilini biladigan xodim bormi? (Ha yoq)",
  Удалить: "O'chirish",
  "Внесенные данные в Infrastructure": "Infrastrukturaga kiritilgan ma'lumotlar",
  "Подтвержденные данные": "Confirmed data",
  soft_skills: "Soft skills",
  hard_skills: "Hard skills",
  languages: "Languages",
  'Запросы в отдел Softlanding': 'Softlanding boʻlimiga soʻrovlar',
  'Перевести в отдел Softlanding': 'Softlanding bo‘limiga o‘tkazish',
  'Направлен запрос в отдел Softlanding': 'Softlanding bo\'limiga so\'rov yuborildi',
  'Изменить этап': 'Bosqichni o\'zgartirish',
  'Редактирование этапа': 'Bosqichni tahrirlash',
  'Турецкий': 'Turk',
  
  "Направление заявки на возмещения":"Xarajatlarni qoplash uchun ariza yo‘nalishi",
  "Заявка на возмещение расходов по программе Local2Global":"Local2Global dasturi bo‘yicha xarajatlarni qoplash uchun ariza",
  "Выберите направление для возмещения расходов на которую вы подаете заявку":"Xarajatlarni qoplash uchun ariza topshirayotgan yo‘nalishni tanlang",
  "Предоставьте вашу контактную информацию":"Kontakt ma’lumotlaringizni taqdim eting",
  "Имя пользователя Telegram":"Telegram foydalanuvchisi nomi",
  "Имя пользователя WhatsApp":"WhatsApp foydalanuvchi nomi",
  "Предоставьте информацию о поездке":"Safar haqida ma’lumot bering",
  "Даты поездки":"Safar sanasi",
  "Пункт назначения":"Borish manzili",
  "Цель поездки":"Safar maqsadi",
  "Предоставьте информацию о мероприятии":"Tadbir haqida ma'lumot bering",
  "Название мероприятия":"Tadbir nomi",
  "Даты участия":"Ishtirok sanasi",
  "Цель участия":"Ishtirok etish maqsadi",
  "Предоставьте информацию о консультанте":"Maslahatchi haqida ma'lumot bering",
  "Имя консультанта/специалиста":"Konsultant/mutaxassisning ismi",
  "Объем работы":"Ish hajmi",
  "Сроки сотрудничества":"Hamkorlik muddati",
  "Предоставьте информацию о гостях":"Mehmonlar haqida ma'lumot bering",
  "Имя гостя":"Mehmonning ismi",
  "Цель визита":"Tashrif maqsadi",
  "Даты пребывания":"Yashash sanasi",
  "Загрузите документы (в формате docx, pdf не более 15 МБ)":"Hujjatlarni yuklang (docx, pdf formatida ko‘pi bilan 15 MB)",
  
  'The route of the trip':'Safar marshruti',
  'Plane tickets and boarding passes':'Turar joy kvitansiyalari',
  'Receipts for accommodation':'Uchrashuvlar bayonnomalari/hisobotlar',
  'Detailed expense report':'Samolyot chiptalari va qo‘nish talonlari',
  'Meeting minutes/reports':'Xarajatlar to‘g‘risida batafsil hisobot',
  'Prospective contracts or Memoranda of Understanding (MOU)':'Shartnomalar yoki Anglashuv Memorandumlari (MOU)',
  'Upload the contract or employment contract of the person who is participating in the event.':'Safar uchun mas’ul bo‘lgan shaxsning shartnomasi yoki mehnat shartnomasini yuklang',
  
  'Confirmation of registration for the conference/event':'Konferensiya/tadbirga ro‘yxatdan o‘tganlikni tasdiqlashti',
  'Receipts for payment of registration fees':'Ro‘yxatga olish badallari to‘langanligi to‘g‘risidagi kvitansiyalar',
  'The program of the event':'Tadbir dasturi',
  'Plane tickets and boarding passes':'Samolyot chiptalari va qo‘nish talonlari',
  'Stand/place rental agreement (if applicable)':'Stend/joy ijarasi shartnomasi (agar qo‘llanilsa)',
  'Post-event report (including photos and videos)':'Tadbirdan keyingi hisobot (shu jumladan fotosuratlar va videolar)',
  'Upload the contract or employment contract of the person who is participating in the event.':'Tadbirda ishtirok etayotgan shaxsning shartnomasi yoki mehnat shartnomasini yuklang.',

  'Contract for the provision of consulting services':'Maslahat xizmatlari ko‘rsatish shartnomasi',
  "Consultant's qualifications (resume, portfolio, etc.)":"Konsultantning malakasi (rezyume, portfolio va boshqalar)",
  'Invoices from a consultant/specialist':'Konsultant/mutaxassis hisobvaraqlari',
  'Confirmation of payment (receipts or bank statements)':'To‘lovni tasdiqlash (kvitansiya yoki bankdan ko‘chirma)',
  'Reports and analysis (photos, videos, strategies and action plans)':'Hisobotlar va tahlillar (suratlar, videolar, strategiyalar va harakatlar rejalari)',
  "Upload the consultant's contract or agreement, which specifies the scope of work.":"Konsultantning ish hajmi ko‘rsatilgan shartnoma yoki kelishuvini yuklang.",

  'Official invitations':'Rasmiy takliflar',
  "Guest profiles":"Mehmon profillari",
  'Receipts for air tickets and boarding passes':'Aviachiptalar va qo‘nish talonlari uchun kvitansiyalar',
  'Receipts for accommodation':'Yashash uchun kvitansiyalar',
  'Prospective contracts or Memoranda of Understanding (MOU)':'Shartnomalar yoki Anglashuv Memorandumlari (MOU)',
  "Upload the contract or employment contract of the person who is responsible for organizing the visit.":"Tashrifni tashkil etish uchun mas’ul bo‘lgan shaxsning shartnomasi yoki mehnat shartnomasini yuklang.",
  
  "Ваши заявки на возмещение":"Xarajatlarni qoplash uchun arizalaringiz",
  "Основные сведения":"Asosiy ma’lumotlar",
  "Отчетная документация":"Hisobot hujjatlari",
  "Сохраните введенные данные в калькуляторе перед тем, как отправить заявление Экспертной комиссии.":"Murojaatingizni Ekspert komissiyasiga yuborishirishdan oldin kiritilgan ma’lumotlarni kalkulyatorda saqlang.",
  "Выберите ответственных сотрудников": "Mas'ul xodimlarni tanlang",
  "Выберите тип компании": "Kompaniya turini tanlang",
};
