import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.candidates-for-talent.list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  create({ commit }, data) {
    return method("crm.candidates-for-talent.create", data);
  },

  getSingle({ commit }, data) {
    return method("crm.candidates-for-talent.show", data);
  },

  sentToReview({ commit }, data) {
    return method("crm.candidates-for-talent.send-review", data);
  },

  rejected({ commit }, data) {
    return method("crm.candidates-for-talent.reject", data);
  },

  incomplete({ commit }, data) {
    return method("crm.candidates-for-talent.return-for-incomplete", data);
  },

  confirmed({ commit }, data) {
    return method("crm.candidates-for-talent.confirm", data);
  },

  edit({ commit }, data) {
    return method("crm.candidates-for-talent.edit", data);
  },
  getTableInfo({ commit }, data) {
    return method("crm.candidates-for-talent.statistics-by-region", data);
  },
  generalRanking({ commit }, data) {
    return method("crm.candidates-for-talent.statistics-for-general-ranking", data);
  },
  generalRankingExcelExport({ commit }) {
    return method("crm.candidates-for-talent.regional-ranking-excel-export");
  },
};
